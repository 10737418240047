import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./pages/Auth/Login";
import Forgot from "./pages/Auth/Forgot";
import Confirm from "./pages/Auth/Confirm";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminWrapper from "./layouts/AdminWrapper";
import Ekeng from "./pages/LeadCreate/Ekeng";
import Users from "./pages/Users/Users";
import { UserContext } from "./context/UserContext";
import Register from "./pages/Auth/Register";
import { useTranslation } from "react-i18next";
import User from "./pages/User/User";
import { ToastContainer } from "react-toastify";
import PublicWrapper from "./layouts/PublicWrapper";
import Settings from "./pages/Settings/Settings"
import Setting from "./pages/Settings/Setting";
import Import from "./pages/Import/Import";
import Applications from "./pages/Applications/Applications";
import './i18n'
import Otp from "./pages/Otp/Otp";
import CreateLead from "./pages/LeadCreate/CreateLead";

function App() {
  const {i18n} = useTranslation()
  const [user, setUser] = useState(null);
  useEffect(() => {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : false;
    const access_token = localStorage.getItem("access_token");
    const userInfo = user
      ? {
          ...user,
          token: access_token,
        }
      : false;
    setUser(userInfo);
    setUser(user);
    i18n.on('languageChanged', (a)=>{
      document.querySelector('html').dir  = a === 'en' ? 'ltr': 'rtl'
      
    })
        // i18n.on('languageChanged' , lang =>{
        //   console.log('languageChanged', lang)
        // })

    
  }, []);
  const loginUser = ({ data }) => {
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("access_token", data.access_token);
    setUser(data.user);
  };

  const resetUser = () => {
    setUser(false);
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
  };
   
  return (
    <div className="App" style={{direction:i18n.language==="ab"?'rtl':'ltr'}}>
    <UserContext.Provider value={{ user,  setUser: loginUser, resetUser  }}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PublicWrapper>
                <Login />
              </PublicWrapper>
            }
          />
          <Route
            path="/register"
            element={
              <PublicWrapper>
                <Register />
              </PublicWrapper>
            }
          />
          <Route
            path="/forgot"
            element={
              <PublicWrapper>
                <Forgot />
              </PublicWrapper>
            }
          />
          <Route
            path="/confirm/:token"
            element={
              <PublicWrapper>
                <Confirm />
              </PublicWrapper>
            }
          />

          <Route
            path="/login"
            element={
              <PublicWrapper>
                <Login />
              </PublicWrapper>
            }
          />
          <Route
            path="/otp"
            element={
              <PublicWrapper>
                <Otp />
              </PublicWrapper>
            }
          />

      
          <Route
            path="/leads"
            element={
              <AdminWrapper>
                <Settings />
              </AdminWrapper>
            }
          />
           <Route
            path="/create-lead/:id"
            element={
              <AdminWrapper>
                <CreateLead/>
              </AdminWrapper>
            }
          />
                 <Route
            path="/calculators"
            element={
              <AdminWrapper>
                <Ekeng />
              </AdminWrapper>
            }
          />
          <Route
            path="/lead/:id"
            element={
              <AdminWrapper>
                <Setting />
              </AdminWrapper>
            }
          />
          <Route
            path="/users"
            element={
              <AdminWrapper>
                <Users />
              </AdminWrapper>
            }
          />
           <Route
            path="/user"
            element={
              <AdminWrapper>
                <User/>
              </AdminWrapper>
            }
          />
          <Route
            path="/settings"
            element={
              <AdminWrapper>
                <Import />
              </AdminWrapper>
            }
          />
          <Route
            path="/applications/:id"
            element={
              <AdminWrapper>
                <User />
              </AdminWrapper>
            }
          />
          <Route
            path="/applications"
            element={ 
              <AdminWrapper>
                <Applications />
              </AdminWrapper>
            }
          />
          <Route path="*" element={<div>404 not found</div>} />
        </Routes>
      </Router>
      <ToastContainer />
    </UserContext.Provider>
    </div>
  );
}

export default App;
